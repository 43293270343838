<script context="module">
  import { groupBy } from 'lodash';
  import { Client, getLanguage } from '../utils/prismicHelpers';
  import CustomCallToAction2 from '../components/CustomCallToAction2/CustomCallToAction2.svelte';
  import { createRequest } from '../utils/request';
  import PricingPlans from '../components/PricingPlans.svelte';
  import { COUNTRY_IDS, LANGUAGE_STORAGE_KEY } from '../constants';
  import { browser } from '$app/env';


  export async function load({ session }) {
    const { API_BASE_URL, API_KEY, LANGUAGE } = session;

    try {
      const [mainPageDoc, customerLogos] = await Promise.all([
        Client().getSingle('main_page', {
          lang: getLanguage(LANGUAGE),
        }),
        Client().getSingle('our_customers', { lang: getLanguage() }),
      ]);
      return {
        props: {
          mainPageDoc,
          customerLogos,
          API_BASE_URL,
          API_KEY,
          LANGUAGE,
        },
      };
    } catch (error) {
      return {
        props: {
          API_BASE_URL,
          API_KEY,
          LANGUAGE,
        }
    }}
  }
</script>

<script>
  import { onMount } from 'svelte';
  import { page } from '$app/stores';
  import {tippy} from '$lib/tippy';
  import CategoryGroupSlice from '../components/CategoryGroupSlice.svelte';
  import CategoryGroup from '../components/CategoryGroup.svelte';
  import DemoTemplate from '../components/DemoTemplate.svelte';
  import Service from '../components/Service.svelte';
  import Button from '../components/Button.svelte';
  import ContactForm from '../components/ContactForm.svelte';

  import Laptop from '../components/Laptop.svelte';
  import SliceZone from '../components/SliceZone.svelte';
  import MobileAppBanner from '../components/MobileAppBanner.svelte';
  import { services, demos } from '../data';

  import { _ } from 'svelte-i18n';
  import { getLanguageCode } from '../utils/language';

  export let mainPageDoc, customerLogos, API_BASE_URL, API_KEY, LANGUAGE;

  const request = createRequest({
    apiBaseUrl: API_BASE_URL,
    apiKey: API_KEY,
    language: LANGUAGE,
  });

  let licenseInformation;
  let licenseTypes;
  let pricingPlans;
  let licenseInformationTypes;
  let featureGroupNames;

    // position scroll to anchors correctly
    $: if (
      $page &&
      $page?.url?.hash &&
      licenseInformation &&
      licenseInformation?.data &&
      licenseTypes?.data?.Results
    ) {
    const hash = $page.url.hash;
    if (browser && hash) {
      const deepLinkedElement = document.getElementById(hash.substring(1));

      if (deepLinkedElement) {
        window.setTimeout(() => {
          deepLinkedElement.scrollIntoView();
        }, 1000);
      }
    }
  }

  onMount(async () => {
    const domainParts = window.location.host.split('.');
    let language = LANGUAGE;

    if (domainParts.length > 1) {
      language = getLanguageCode(language);
    }

    const response = await Promise.all([
      request({
        url: '/licenses/information',
        method: 'GET'
      }),
      request({
        url: `/licenses/types?IncludeLicenseItems=false&IncludeLicensePrices=true&CountryId=${COUNTRY_IDS[language]}&ExtraLicenseOption=false`,
        method: 'GET',
      }),
      request({
        url: '/licenses/informationtypes',
        method: 'GET',
      }),
    ]);
    licenseInformation = response[0];
    licenseTypes = response[1];
    licenseInformationTypes = response[2];

    const grouppedByInformationType = groupBy(
      licenseInformation.data.Results,
      (licenseInformation) => {
        const informationTypeKey =
          licenseInformationTypes.data.Results.find(
            (l) => l.Id == licenseInformation['LicenseInformationTypeId']
          ) &&
          licenseInformationTypes.data.Results.find(
            (l) => l.Id == licenseInformation['LicenseInformationTypeId']
          ).Name
            ? licenseInformationTypes.data.Results.find(
                (l) => l.Id == licenseInformation['LicenseInformationTypeId']
              ).Name
            : licenseInformationTypes.data.Results[2].Name;
            
        return informationTypeKey;
      }
    );

    pricingPlans = licenseTypes.data.Results.map((licenseType) => {
      return {
        ...licenseType,
        grouppedFeatures: grouppedByInformationType,
      };
    });
  });

  export let standardPlanFeatures = [
    $_('pricingPlans.features.internetBooking'),
    $_('pricingPlans.features.unlimitedBooking'),
    $_('pricingPlans.features.website'),
    $_('pricingPlans.features.oneDomainName'),
    $_('pricingPlans.features.integrateOnYourWebsite'),
    $_('pricingPlans.features.emailReminders'),
    $_('pricingPlans.features.customerRegister'),
    $_('pricingPlans.features.sendMessageToCustomers'),
    $_('pricingPlans.features.mobileApp'),
    $_('pricingPlans.features.pricingOnServices'),
    $_('pricingPlans.features.cardPayment'),
    $_('pricingPlans.features.offlineSync'),
    $_('pricingPlans.features.services', { values: { number: 10 } }),
    $_('pricingPlans.features.resources', { values: { number: 10 } }),
    $_('pricingPlans.features.administratorAccounts', {
      values: { number: 5 },
    }),
  ];
  export let premiumPlanFeatures = [
    $_('pricingPlans.features.internetBooking'),
    $_('pricingPlans.features.unlimitedBooking'),
    $_('pricingPlans.features.website'),
    $_('pricingPlans.features.oneDomainName'),
    $_('pricingPlans.features.integrateOnYourWebsite'),
    $_('pricingPlans.features.emailReminders'),
    $_('pricingPlans.features.customerRegister'),
    $_('pricingPlans.features.sendMessageToCustomers'),
    $_('pricingPlans.features.mobileApp'),
    $_('pricingPlans.features.offlineSync'),
    $_('pricingPlans.features.pricingOnServices'),
    $_('pricingPlans.features.cardPayment'),
    $_('pricingPlans.features.offlineSync'),
    $_('pricingPlans.features.services', { values: { number: 25 } }),
    $_('pricingPlans.features.resources', { values: { number: 25 } }),
    $_('pricingPlans.features.administratorAccounts', {
      values: { number: 10 },
    }),
  ];

  const callToAction =
    mainPageDoc && mainPageDoc.data && mainPageDoc.data.call_to_action[0];

  const categories =
    mainPageDoc && mainPageDoc.data && mainPageDoc.data.categories;
</script>

<svelte:head>
  <title>{$_('mainPage.title')}</title>
  <meta name="description" content={$_('mainPage.description')} />
</svelte:head>

<div class="banner">
  <div class="banner__image">
    <Laptop />
  </div>
  <div class="banner__content">
    <div class="banner__card">
      <h1 class="banner__title">
        {@html $_('subTitle')}
      </h1>
      <div class="banner__description">{$_('companyDescription')}</div>
      <div class="banner__action">
        <a class="banner__button" rel="stylesheet" href="/sign-up">
          <Button large uppercase>{$_('mainPage.getStarted')}</Button>
        </a>
      </div>
    </div>
  </div>
</div>

{#if customerLogos && customerLogos.data && customerLogos.data.logos.length > 0}
  <div class="clients">
    <h3 class="clients__heading">{$_('mainPage.ourCustomers')}</h3>
    <div class="clients__container container">
      {#each customerLogos.data.logos as logo}
        <img loading="lazy" src={logo.image.url} alt={logo.image.alt} />
      {/each}
    </div>
  </div>
{/if}

{#if categories && categories.length > 0}
  <div class="categories">
    <div class="container">
      <h3 class="categories__title">
        {$_('mainPage.worksWithManyCategories')}
      </h3>
      <div class="categories__cards">
        {#each categories as category}
          <CategoryGroupSlice
            title={category.primary.heading[0].text}
            categories={[
              {
                label: category.primary.link_1_label,
                url: category.primary.link_1_url,
              },
              {
                label: category.primary.link_2_label,
                url: category.primary.link_2_url,
              },
              {
                label: category.primary.link_3_label,
                url: category.primary.link_3_url,
              },
            ]}
            imgSrc={category.primary.image.url}
            link={category.primary.card_link}
            resposiveImages={category.primary.image}
          />
        {/each}
      </div>
    </div>
  </div>
{:else}
  <div class="categories">
    <div class="container">
      <h3 class="categories__title">
        {$_('mainPage.worksWithManyCategories')}
      </h3>
      <div class="categories__cards">
        <CategoryGroup
          title={$_('categories.1.title')}
          categories={[
            $_('categories.1.items.1'),
            $_('categories.1.items.2'),
            $_('categories.1.items.3'),
          ]}
          imgSrc="categories/beauty.jpg"
          link="/categories"
        />
        <CategoryGroup
          title={$_('categories.2.title')}
          categories={[
            $_('categories.2.items.1'),
            $_('categories.2.items.2'),
            $_('categories.2.items.3'),
          ]}
          imgSrc="categories/sport.jpg"
          link="/categories"
        />
        <CategoryGroup
          title={$_('categories.3.title')}
          categories={[
            $_('categories.3.items.1'),
            $_('categories.3.items.2'),
            $_('categories.3.items.3'),
          ]}
          imgSrc="categories/rental.jpg"
          link="/categories"
        />
        <CategoryGroup
          title={$_('categories.4.title')}
          categories={[
            $_('categories.4.items.1'),
            $_('categories.4.items.2'),
            $_('categories.4.items.3'),
          ]}
          imgSrc="categories/workshop.jpg"
          link="/categories"
        />
      </div>
    </div>
  </div>
{/if}

{#if callToAction && callToAction.primary}
  <CustomCallToAction2
    primary={callToAction.primary}
    items={callToAction.items}
  />
{/if}

<MobileAppBanner />

{#if mainPageDoc}
  <SliceZone slices={mainPageDoc.data.body} />
{/if}

<div class="enterprise-banner">
  <div class="enterprise-banner__content">
    <div class="enterprise-banner__card">
      <div class="enterprise-banner__title">
        {@html $_('enterprise.banner.title')}
      </div>
      <div class="enterprise-banner__description">{$_('enterprise.banner.description')}</div>
      <div class="enterprise-banner__buttons">
        <a href="/enterprise">
          <Button large uppercase>{$_('links.enterprise')}</Button>
        </a>
      </div>
    </div>
  </div>
</div>

<div class="demos">
  <h3>{$_('demos.title')}</h3>
  <div class="demos__cards container">
    {#each demos.slice(0, 2) as demo}
      <DemoTemplate
        username={demo.username}
        password={demo.password}
        title={demo.title}
        description={demo.description}
        imgSrc={demo.imgSrc}
        homepageLink={demo.homepageLink}
        adminLink={demo.adminLink}
      />
    {/each}
  </div>
  <div class="section-link-container">
    <a href="/demo">{$_('viewAllDemos')} »</a>
  </div>
</div>

<div class="rco-banner">
  <div class="rco-banner__content">
    <div class="rco-banner__card">
      <div class="rco-banner__title">
        {@html $_('rco.banner.title')}
      </div>
      <div class="rco-banner__description">{$_('rco.banner.description')}</div>
      <div class="rco-banner__buttons">
        <a href="/code-lock">
          <Button large uppercase>{$_('links.codeLock')}</Button>
        </a>
      </div>
    </div>
  </div>
</div>

<div class="services">
  <div class="services__content">
    <h3 class="services__title">{$_('ourServices')}</h3>
    <div class="services__items">
      {#each services.slice(0, 6) as service}
        <div class="services__item">
          <Service
            imgSrc={service.imgSrc}
            title={service.title}
            description={service.description}
          />
        </div>
      {/each}
    </div>
    <div class="section-link-container">
      <a href="/features">{$_('viewAllServices')} »</a>
    </div>
  </div>
</div>

<div id="pricing" class="pricing">
  {#if licenseInformation && licenseInformation.data && licenseTypes.data && licenseTypes.data.Results}
    <h3>{$_('ourPricing')}</h3>
    <div class="pricing__inner">
      {#each licenseInformationTypes.data.Results.map((l) => l.Name) as groupName, groupIndex}
        {#if groupIndex > 0}
          <div class="pricing__group-name">{groupName}</div>
        {/if}
        <div class="pricing__plans group-{groupIndex}">
          {#each pricingPlans as type, typeIndex}
            {#if typeIndex === 0}
              <div class="plan-col name">
                {#if groupIndex === 0}
                  <h2>Our Features</h2>
                {/if}
                {#each type.grouppedFeatures[groupName] as { Name, Description, Url }, featureIndex}
                  <div
                    class="feature-name {featureIndex === 0 && groupIndex === 0
                      ? 'big'
                      : ''}"
                  >
                    {#if Url}
                      <a href={Url}>
                        <span>{Name}</span>
                      </a>
                    {:else}
                      <span>{Name}</span>
                    {/if}
                    <img
                      src="/info-icon.png"
                      srcset="/info-icon@2x.png 2x, /info-icon@3x.png 3x"
                      alt="Feature information"
                      use:tippy={{
                        content: Description,
                        placement: "right"
                      }}
                    />
                  </div>
                {/each}
              </div>
            {/if}
            <PricingPlans
              {type}
              {groupName}
              licensesInformation={type.grouppedFeatures}
              hideHeader={groupIndex > 0}
            />
          {/each}
        </div>
      {/each}
    </div>
  {/if}
</div>

<div id="contact" class="contact-us">
  <h3>{$_('contactUs.title')}</h3>

  <div class="contact-us__subheader">{$_('contactUs.description')}</div>
  <div class="contact-us__subsubheader">{$_('contactUs.noBookingsHelp')}</div>
  <div class="contact-us__contactinfo">
    {@html $_('contactUs.contactInfo', { })}
  </div>
  <ContactForm {LANGUAGE} {request} title={$_('index.contactForm.title')} />
</div>

<style>
  .banner {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dddddd;
    width: 100%;
    color: #000;
    background-image: url('/banner.jpg');
    background-position: center;
    background-size: cover;
    padding: 40px 20px;
  }

  .banner__content {
    flex: 0 1 50%;
    padding: 14px;
    margin-left: 50px;
  }
  .banner__image {
    flex: 0 1 50%;
    width: 50%;
  }
  .banner__title {
    text-align: left;
    font-weight: 600;
    font-size: 32px;
    margin-top: 0;
    margin-bottom: 23px;
  }
  .banner__description {
    margin-bottom: 27px;
    font-size: 18px;
  }
  .banner__button {
    margin-right: 5px;
    margin-bottom: 10px;
    display: inline-block;
  }

  .rco-banner__buttons {
    text-align: right;
  }

  .clients {
    padding-top: 48px;
  }
  .clients__heading {
    margin-bottom: 20px;
  }
  .clients__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .clients img {
    filter: grayscale(100%);
    max-width: 130px;
    max-height: 100px;
    margin: 15px 30px;
  }

  .categories {
    padding-top: 40px;
    padding-bottom: 36px;
  }
  .categories__title {
    margin-bottom: 40px;
  }
  .categories__cards {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 0 -15px 42px;
  }
  .categories__cards :global(.category-group) {
    margin: 0 15px 30px;
  }

  .section-link-container {
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    display: block;
  }
  .section-link-container a {
    text-decoration: underline;
    color: #000;
  }

  .demos {
    padding-top: 76px;
    padding-bottom: 76px;
  }
  .demos__cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1200px;
    width: 100%;
    margin-bottom: 52px;
  }

  .services {
    padding: 50px 20px;
  }
  .services__title {
    margin-bottom: 72px;
    size: 36px;
  }
  .services__content {
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
  }
  .services__items {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .services__item {
    width: 30%;
    margin-bottom: 60px;
  }

  .pricing {
    padding: 50px 20px;
  }
  .pricing__plans {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .pricing__inner {
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  .pricing__group-name {
    background-color: #f8fafc;
    box-shadow: 1px 0 0 0 rgba(76, 75, 76, 0.08);
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .feature-name {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: space-between;
    box-shadow: 0 0 0 0 rgba(76, 75, 76, 0.18);
    font-family: Poppins;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.23;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }

  .feature-name.big {
    height: 60px;
  }

  .feature-name a {
    color: #000000;
    cursor: pointer;
  }
  .feature-name img {
    width: 15px;
    height: 15px;
  }
  .plan-col {
    display: flex;
    flex-direction: column;
    display: none;
  }
  .plan-col h2 {
    margin: 123px 41px 18px 26px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }
  .plan-col.name {
    min-width: 250px;
  }
  .feature-name {
    height: 60px;
  }
  .footnotes {
  }

  .pricing__group-name {
    display: none;
  }

  @media only screen and (min-width: 961px) {
    .feature-name.big {
      height: 100px;
    }
    .feature-name:nth-of-type(odd) {
      background-color: #f8fafc;
    }

    .pricing__plans {
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: flex-start;
    }

    .plan-col {
      display: flex;
      flex-basis: 171px;
    }
  }
  .pricing__plans.group-2 .feature{
      border: 1px solid red; 
    }
  @media only screen and (max-width: 60em) {
    .pricing__plans.group-1 {
      display: none;
    }
    .pricing__plans.group-2 {
      display: none;
    }
  }
  @media only screen and (min-width: 60em) {
    .feature-name {
      height: 60px;
    }
    :global(.pricing__plans.group-0 .feature.group-1, .pricing__plans.group-0
        .feature.group-2) {
      display: none;
    }

    :global(.pricing__plans.group-1 .feature.group-0, .pricing__plans.group-1
        .feature.group-2) {
      display: none;
    }

    :global(.pricing__plans.group-2 .feature.group-0, .pricing__plans.group-2
        .feature.group-1) {
      display: none;
    }

    .pricing__group-name {
      display: flex;
    }
    .plan-col h2 {
      margin: 94px 41px 18px 26px;
    }
  }

  @media only screen and (max-width: 64em) {
    .pricing__plans {
      /* gap: 0; */
    }
  }
  @media only screen and (min-width: 1025px) {
    .plan-col {
      /* margin-right: 20px; */
    }

    .plan-col.name {
      max-width: none;
      /* flex-basis: 334px */
    }
  }

  @media only screen and (min-width: 1400px) {
    .plan-col h2 {
      margin: 119px 41px 18px 26px;
    }
  }

  .contact-us {
    padding: 50px 20px;
    background-position: center;
    background-size: cover;
  }
  .contact-us__subheader {
    width: 100%;
    text-align: center;
    line-height: 28px;
    margin-bottom: 33px;
    font-size: 18px;
  }

  .contact-us__subsubheader {
    width: 100%;
    text-align: center;
    line-height: 28px;
    font-size: 14px;
    margin-bottom: 16px;
  }

  .contact-us__contactinfo {
    width: 100%;
    text-align: center;
    margin-bottom: 33px;
    font-size: 14px;
    max-width: 532px;
    margin: auto;
    margin-bottom: 33px;
    padding: 14px;
    margin-bottom: 33px;
  }

  .contact-us__contactinfo :global(p) {
    margin: 0;
  }

  h3 {
    margin-bottom: 35px;
  }

  .rco-banner {
    background-color: #46342e;
    background-image: url('/rco-lock.jpg');
  }
  .enterprise-banner {
    background-color: #46342e;
    background-image: url('/enterprise.jpg');
  }
  .rco-banner, .enterprise-banner {
    width: 100%;
    height: 520px;
    display: flex;
    justify-content: center;
    background-position: center;
    background-size: cover;
  }
  .rco-banner__content, .enterprise-banner__content {
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
  }
  .rco-banner__card, .enterprise-banner__card {
    width: 553px;
    background-color: hsla(0, 0%, 100%, 0.95);
    padding: 32px 38px;
  }
  .rco-banner__title, .enterprise-banner__title {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 21px;
  }
  .rco-banner__description, .enterprise-banner__description {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 35px;
  }

  @media (max-width: 600px) {
    .banner {
      flex-direction: column-reverse;
      height: auto;
      align-items: center;
      padding: 20px 20px 40px;
    }
    .banner__image {
      width: 100%;
    }
    .banner__content {
      margin-left: 0;
      padding: 0;
      margin-bottom: 20px;
    }
    .banner__title {
      font-size: 22px;
    }
    .banner__description {
      font-size: 16px;
    }
    .banner__action {
      text-align: center;
    }
    .clients {
      height: auto;
    }
    .clients__container {
      justify-content: space-around;
    }
    .clients img {
      max-width: 100px;
      margin: 12px 10px;
    }
    .more-areas__list {
      flex-direction: column;
    }
    .mobile-apps {
      height: 280px;
    }
    .mobile-apps__brand {
      display: none;
    }
    .mobile-apps__content {
      left: 30%;
      padding-right: 20px;
      text-align: right;
    }
    .mobile-apps__button {
      margin: 6px 0;
    }
    .mobile-apps__title {
      font-size: 24px;
    }
    .demos__cards {
      flex-direction: column;
    }
    .services__item {
      width: 100%;
    }
    .pricing__plans {
      flex-direction: column;
    }
    .pricing__plans :global(.plan) {
      width: auto;
    }

    h3 {
      font-size: 24px;
      line-height: 28px;
    }
    .rco-banner, .enterprise-banner {
      background-position-x: 65%;
    }
    .rco-banner__content, .enterprise-banner__content {
      align-items: flex-end;
    }
    .rco-banner__title, .enterprise-banner__title {
      font-size: 24px;
    }
    .rco-banner__description, .enterprise-banner__description {
      display: none;
    }
  }
</style>
